import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import ZoomOutIcon from '@mui/icons-material/ZoomOut';
import TextField from '@mui/material/TextField';
import PlagiarismIcon from '@mui/icons-material/Plagiarism';
import { useAuth } from '../App';
import { Document, Page, pdfjs } from 'react-pdf';
import 'react-pdf/dist/Page/AnnotationLayer.css';
import 'react-pdf/dist/Page/TextLayer.css';
import { Box, Toolbar } from '@mui/material';
import { NotificationContext } from '../App';
import { getDocumentMetadata, getDocumentText } from '../interactors/documents';
import { FileAPIURL, getFileOrginal, getFileImage } from '../interactors/file';

pdfjs.GlobalWorkerOptions.workerSrc = 'lib/pdf.worker.min.mjs';

function highlightPattern(text:string, pattern:string) {
  return text.replace(new RegExp(pattern,'gi'), (value: string) => `<mark>${value}</mark>`);
}

export default function Viewer() {
  const { token } = useAuth();
  const [searchParams, setSearchParams] = useSearchParams();
  const queryEmbed = Boolean(searchParams.get('embed') || '');
  const queryDocumentId = searchParams.get('doc') || '';
  const queryVersionId = searchParams.get('version') || '';
  const queryPageNumber = Number(searchParams.get('page') || '1');
  const queryHighlight = searchParams.get('highlight') || '';
  const { setToastMessage } = useContext(NotificationContext);

  const [numPages, setNumPages] = useState<number>();
  const [pageNumber, setPageNumber] = useState<number>(queryPageNumber);
  const [scaleLevel, setScaleLevel] = useState<number>(1.5);
  const [embed, setEmbed] = useState<Boolean>(queryEmbed);
  const [documentIdText, setDocumentIdText] = useState<string>(queryDocumentId);
  const [versionIdText, setVersionIdText] = useState<string>(queryVersionId);

  const [documentId, setDocumentId] = useState<string>(queryDocumentId);
  const [highlightText, setHighlightText] = useState<string>(queryHighlight);
  const [metadata, setMetadata] = useState<Object | null>(null);
  const [fileUrl, setFileUrl] = useState<string>('');
  const file = useMemo(() => ({ url: fileUrl }), [fileUrl]);
  const options = useMemo(() => ({
    httpHeaders: { authorization: `Bearer ${token}` },
    }),
    [token]
  );

  function onDocumentLoadSuccess({ numPages }: { numPages: number }): void {
    setNumPages(numPages);
  }

  const textRenderer = useCallback((textItem) => highlightPattern(textItem.str, highlightText), [highlightText]);

  const handleKeyDownDocument = (e) => {
    if (['Enter', 'Tab'].includes(e.key)) {
      handleUpdateDocumentId(documentIdText);
    }
  };

  const handleKeyDOwnHighlight = (e) => {
    if (['Enter', 'Tab'].includes(e.key)) {
      handleUpdateHighlight(highlightText);
    }
  };

  const handleUpdateDocumentId = async (doc: string) => {
    if (doc !== documentId) {
      setDocumentId(doc);
    }
    const url = `${FileAPIURL}file/${doc}/pdf-or-original`;
    if (url === fileUrl) return;
    const document = await getDocumentMetadata(doc);
    setFileUrl(url);
    setMetadata(document.metadata);
    setSearchParams({ doc, page: '1', version: versionId, highlight: highlightText, embed });
  };

  const handleUpdatePageNumber = (page: number) => {
    setPageNumber(page);
    setSearchParams({ doc: documentId, version: versionId, page: page.toString(), highlight: highlightText, embed });
  };

  const handleUpdateHighlight = (highlight: string) => {
    setHighlightText(highlight);
    setSearchParams({ doc: documentId, version: versionId, page: pageNumber.toString(), highlight, embed });
  };

  useEffect(() => {
    if (documentIdText && versionIdText) {
      handleUpdateDocumentId(documentIdText);
    } else if(documentIdText){
      handleUpdateDocumentId(documentIdText);
    }
  }, [documentIdText, versionIdText]);

  return (
    <Paper elevation={2} sx={{ p: 2 }}>
      <Toolbar>
        <TextField
          type="text"
          value={documentIdText}
          onChange={(e) => setDocumentIdText(e.target.value)}
          placeholder="Enter document ID"
          onKeyDown={handleKeyDownDocument}
          sx={{
            display: 'flex',
            alignItems: 'center',
            border: '1px solid',
            borderColor: 'divider',
            borderRadius: 1,
            bgcolor: 'background.paper',
            color: 'text.secondary',
            '& svg': {
              m: 1,
            },
            '& hr': {
              mx: 0.5,
            },
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => handleUpdateDocumentId(documentIdText, versionIdText)}>
                  <PlagiarismIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <TextField
          type="text"
          value={highlightText}
          onChange={(e) => setHighlightText(e.target.value)}
          placeholder="Highlight Text"
          onKeyDown={handleKeyDOwnHighlight}
          sx={{
            display: 'flex',
            alignItems: 'center',
            border: '1px solid',
            borderColor: 'divider',
            borderRadius: 1,
            bgcolor: 'background.paper',
            color: 'text.secondary',
            '& svg': {
              m: 1,
            },
            '& hr': {
              mx: 0.5,
            },
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => handleUpdateHighlight(highlightText)}>
                  <PlagiarismIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}></Typography>
        {numPages && (
          <Box>
            <IconButton onClick={() => setScaleLevel(scaleLevel - 0.5)}>
              <ZoomOutIcon />
            </IconButton>
            <TextField type="number" size="small" value={scaleLevel} onChange={(e) => setScaleLevel(Number(e.target.value))} placeholder="Enter Scale Level" sx={{ width: 50 }} />
            <IconButton onClick={() => setScaleLevel(scaleLevel + 0.5)}>
              <ZoomInIcon />
            </IconButton>
            {/* <Divider orientation="vertical" flexItem /> */}
            <IconButton onClick={() => handleUpdatePageNumber(1)} disabled={pageNumber === 1}>
              <KeyboardDoubleArrowLeftIcon />
            </IconButton>
            <IconButton onClick={() => handleUpdatePageNumber(Math.max(pageNumber - 1, 1))} disabled={pageNumber === 1}>
              <KeyboardArrowLeftIcon />
            </IconButton>
            <TextField
              type="number"
              size="small"
              value={pageNumber}
              onChange={(e) => handleUpdatePageNumber(Math.min(Number(e.target.value), numPages))}
              placeholder="Enter Page Number"
              sx={{ width: 70 }}
            />
            <TextField type="text" size="small" value={numPages} disabled={true} sx={{ width: 70 }} />
            <IconButton onClick={() => handleUpdatePageNumber(Math.min(pageNumber + 1, numPages))} disabled={pageNumber == numPages}>
              <KeyboardArrowRightIcon />
            </IconButton>
            <IconButton onClick={() => handleUpdatePageNumber(numPages)} disabled={pageNumber == numPages}>
              <KeyboardDoubleArrowRightIcon />
            </IconButton>
          </Box>
        )}
      </Toolbar>
      {fileUrl && file && (
        <Document
          file={file}
          options={options}
          onLoadError={(err)=>setToastMessage({ message: err, severity: 'error' })}
          onLoadSuccess={onDocumentLoadSuccess}
          className="pdf-document"
        >
          {/* <Outline /> */}
          <Page pageNumber={pageNumber} className="pdf-page" scale={scaleLevel} customTextRenderer={textRenderer} loading={<CircularProgress sx={{ ml: '45%', mt: 20, mb: 20 }} color="secondary" />} />
        </Document>
      )}
    </Paper>
  );
}
